<template>

<app-card :theme="theme" :icon="icon" :title="name" :subtitle="type">

	<app-card-action :disabled="isAssigned" icon="assign" tooltip="Assign user" v-on:click="is.assigning = true" />
	<app-card-action :disabled="isAssigned" icon="edit" tooltip="Edit badge" v-on:click="is.editing = true" />

	<com-edit v-if="is.editing" v-on:close="is.editing = false" :item="item" v-on:saved="$emit('saved')" />
	<com-assign v-if="is.assigning" v-on:close="is.assigning = false" :item="item" v-on:saved="$emit('saved')" />

</app-card>

</template>

<script>

import comEdit from './Edit'
import comAssign from './Assign'

export default {

	components: {
		'com-edit': comEdit,
		'com-assign': comAssign
	},

	props: ['item'],

	data: function() {

		return {
			is: {
				editing: false,
				assigning: false
			}
		}

	},

	computed: {

		isAssigned: function() {

			return this.item.method === this.$constants.badge.method.assigned

		},

		theme: function() {

			return (this.isAssigned) ? 'green' : 'yellow'

		},

		icon: function() {

			return (this.isAssigned) ? 'assigned' : 'unassigned'

		},

		reference: function() {

			return 'Ref. ' + this.item.reference

		},

		name: function() {

			return this.item.name

		},

		type: function() {

			return this.item.type.name + ' - ' + this.reference

		}

	}

}

</script>

<style scoped>

.badge-reference {
	position: absolute;
	bottom: 10px;
	font-size: 12px;
}

</style>
