<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Edit badge" />

	<app-panel-text>

		You can keep this badge unassigned for general use or until you decide who to assign it to. While unassigned you can enter a more appropriate name or role.

	</app-panel-text>

	<app-panel-content>

		<app-input-text label="Firstname" v-model="model.firstname" :stacked="true" placeholder="Enter firstname" />
		<app-input-text label="Lastname" v-model="model.lastname" :stacked="true" placeholder="Enter lastname" />

	</app-panel-content>

	<app-panel-content>

		<app-button text="Confirm" :status="status" :loading="is.loading" v-on:click="onConfirmClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			status: false,
			is: {
				loading: false,
				saved: false
			},
			model: {
				firstname: '',
				lastname: ''
			}
		}

	},

	created: function() {

		this.$pubsub.$emit('offset', true)

		this.model.firstname = this.item.firstname
		this.model.lastname = this.item.lastname

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true
			this.status = false

			this.$api.put(['convention/registration/managed/badge', this.item.id], this.model).then(function() {

				this.is.loading = false
				this.is.saved = true

				this.status = this.$constants.status.success
				
				this.$emit('saved')

			}.bind(this), function() {

				this.is.loading = false

				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.connect {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px;
}

.connect-avatar {
	width: 128px;
	height: 128px;
	border-radius: 50%;
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 10px;
}

.connect-username {
	font-size: 20px;
	letter-spacing: 1px;
}

</style>
